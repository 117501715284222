import React, { useState } from 'react';

import styled from 'styled-components';

import { FormInput } from '../../FormComponents/FormInput';
import { SecondaryButtonStyled } from '../GenericStyled/StyledSecondaryButton';
import { Item } from '../Generic/Item';

// const IngredientsListBuilderStyled = styled.div`
//     margin: ${s.pageMargin}
// `

const IngredientsListStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    border: 2px black solid;
    border-radius: 10px;
    padding: 10px;
    max-height: 400px;
`

export const IngredientsListBuilder = (p) => {
    const [removeOnUpdate, setRemoveOnUpdate] = useState([]);

    const onEnterIngredient = (input) => {
        const updated = [...p.ingredients, input];
        p.setIngredients(updated);
    }

    const trackRemoveIngredient = (ingredient) => {
        if (removeOnUpdate.includes(ingredient)) {
            const updated = removeOnUpdate.filter((x) => x === ingredient);
            setRemoveOnUpdate(updated);
        } else {
            setRemoveOnUpdate([...removeOnUpdate, ingredient]);
        }
    }

    const updateToRemove = () => {
        let updated = p.ingredients;

        removeOnUpdate.forEach((removed) => {
            updated = updated.filter((ingredient) => ingredient !== removed);
        });

        p.setIngredients(updated);
        setRemoveOnUpdate([]);
    }

    return (
        <div>
            <FormInput nameKey="ingredient" displayName="Enter Ingredient" type="text" onEnter={onEnterIngredient} />
            {p.ingredients.length > 0 &&
                <>
                    <IngredientsListStyled>
                        {p.ingredients.map(ingredient =>
                            <Item
                                key={ingredient}
                                value={ingredient}
                                trackRemoveItem={trackRemoveIngredient}
                            />
                        )}
                    </IngredientsListStyled>
                    <SecondaryButtonStyled onClick={updateToRemove}>Update</SecondaryButtonStyled>
                </>
            }
        </div>
    )
}