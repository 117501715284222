import React, { useState } from 'react';
import styled from 'styled-components';
import { colorPalette } from '../../assets/colorPalette';
import { dashboardModes } from '../../constants/dashboardModes';
import { StyledButton } from '../GenericStyled/StyledButton';
// import { FullHeightContainer } from '../GenericStyled/StyledFullHeightContainer';
import { Recipes } from '../Recipes/Recipes';
import { ShoppingLists } from '../ShoppingLists/ShoppingLists';

const LeftBarStyled = styled.div`
    display: flex;
    flex: 1 0 300px;
    flex-direction: column;
    border: ridge 5px ${colorPalette.mahogany_dd};
    border-radius: 5px;
    margin: 5px;
    box-sizing: border-box;
    background-color: ${colorPalette.bamboo_dd};
    padding: 10px 5px;
    align-items: center;
    max-width: 300px;
`

const ModeToggleStyled = styled.div`
    display: flex;
    width: 100%;
`
const ModeButton = styled(StyledButton)`
    font-size: 0.8em;
    flex 1 0 auto;
`

export const LeftBar = ({dashboardMode, setDashboardMode, canMealPlan}) => {
    const [mode, setMode] = useState('recipes');

   return (
    <LeftBarStyled>
        {/* <FullHeightContainer> */}
        <ModeToggleStyled>
            <ModeButton onClick={() => setMode('recipes')}>Menu</ModeButton>
            <ModeButton onClick={() => setMode('shopping-lists')}>Shopping Lists</ModeButton>
        </ModeToggleStyled>
        {mode === 'recipes' && <Recipes addNewRecipe={() => setDashboardMode(dashboardModes.RECIPE_FORM)} canMealPlan={canMealPlan} isCreatingShoppingList={dashboardMode === dashboardModes.GENERATE_SHOPPING_LIST} showShoppingList={() => setDashboardMode(dashboardModes.GENERATE_SHOPPING_LIST)}/>}
        {mode === 'shopping-lists' && <ShoppingLists dashboardMode={dashboardMode} addNewShoppingList={() => setDashboardMode(dashboardModes.SHOPPING_LIST_FORM)} combineShoppingLists={() => setDashboardMode(dashboardModes.COMBINE_SHOPPING_LISTS)} />}

        {/* </FullHeightContainer> */}
    </LeftBarStyled>
    )
};