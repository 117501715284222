import React from 'react';
import styled from 'styled-components';

import {
    inputMargin,
    inputPadding,
    StyledInput,
} from './StyledInput';

const InputWithSubmit = styled.div`
    display: flex;
`

const StyledInputSubmit = styled.button`
    padding: ${inputPadding};
    margin: ${inputMargin}
    background-color: white;
`

export const StyledInputWithButton = (p) =>
    <InputWithSubmit>
        <StyledInput
            type={p.type}
            name={p.name}
            value={p.value}
            onChange={p.onChange}
            onKeyPress={p.onKeyPress}
        />
        <StyledInputSubmit type="submit" onClick={p.handleEnter}>Add</StyledInputSubmit>
    </InputWithSubmit>