import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { colorPalette } from '../../assets/colorPalette';
import { fonts } from '../../assets/fonts';
import { PaperPlateContext } from '../../store/PaperPlateProvider';
import { RecipeDeleteButton } from './RecipeDeleteButton';

const RecipeButtonStyled = styled.button`
    width: 100%;
    padding: 13px 10px;
    margin: -5px 5px;
    font-family: ${fonts.jost};
    font-size: 1em;
    background-color: ${colorPalette.cardstock};
    color: ${colorPalette.ash};
    border-radius: 10px 10px 5px 5px;
    text-align: left;
    border: outset black;
    display: flex;
    justify-content: space-between;

    ${props => props.selected && css`
    background-color: ${colorPalette.bamboo};
    `}
`

export const RecipeButton = ({onClick, recipe, unselectAll, canMealPlan, withDelete }) => {
    const [isSelected, setIsSelected] = useState(false);
    const {dragRef, dragHandlers, mealPlan, mealPlanHandlers} = useContext(PaperPlateContext);
    const {onDragStart, onDrop} = dragHandlers;

    useEffect(() => {
        if(unselectAll) {
        setIsSelected(false);
        };
    }, [unselectAll])

    const handleClick = () => {
        onClick(recipe);
        setIsSelected(true);
    }

    const handleDrop = (e) => {
        onDrop(e, mealPlan, mealPlanHandlers.updateMealPlan);
    }

    return (
        <RecipeButtonStyled 
            ref={dragRef}
            type='button' 
            onDragStart={() => onDragStart(recipe)}
            onDragEnd={e => handleDrop(e)}
            onClick={handleClick}
            selected={isSelected}
            draggable={canMealPlan}
        >
        {recipe.recipeName}
            {withDelete && <RecipeDeleteButton iconName='fa-solid fa-trash-can' recipeId={recipe.recipeId}/>}
        </RecipeButtonStyled>
    );
}