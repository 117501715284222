import { getMealPlans } from "./getMealPlans"
import { getRecipes } from "./getRecipes";
import { getShoppingLists } from "./getShoppingLists";

export const getInitialData = async (userBlob, updateStateHandlers) => {
    const {mealPlanHandlers, recipeHandlers, shoppingListHandlers} = updateStateHandlers;
    
    await getMealPlans(userBlob, mealPlanHandlers.updateMealPlan);
    await getRecipes(userBlob, recipeHandlers.replaceRecipes);
    await getShoppingLists(userBlob, shoppingListHandlers.replaceShoppingLists);
};
