import { userActions} from "./store";

export const userReducer = (state, action) => {
    switch (action.type) {
      case userActions.UPDATE_USER: {
        return {
            ...state,
            user: action.userData,
        };
        }
      default:
        return state;
    }
  }