import React, { useState } from 'react';

import styled from 'styled-components';

import {
  StyledInputWithButton,
} from '../components/GenericStyled/StyledInputWithButton';
import { StyledLabel } from '../components/GenericStyled/StyledLabel';
import { onPressEnter } from '../helpers/onPressEnter';

const FormInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const FormInput = (p) => {
    const [value, setValue] = useState('');
    const handleChange = (e) => setValue(e.target.value);

    const handleEnter = () => {
        p.onEnter(value);
        setValue('');
    }

    return (
        <FormInputWrapper>
            <StyledLabel for={p.nameKey}>{p.displayName}</StyledLabel>
            <StyledInputWithButton
                type={p.type}
                name={p.nameKey}
                value={value}
                onChange={handleChange}
                onKeyPress={(e) => onPressEnter(e, handleEnter)}
            />
        </FormInputWrapper>
    )
}