import React, { useContext } from 'react'
import axios from 'axios'

import styled from 'styled-components'

import s from '../../assets/styles'
import { FormInputControlled } from '../../FormComponents/FormInputControlled'
import { PaperPlateContext } from '../../store/PaperPlateProvider'
import { colorPalette } from '../../assets/colorPalette'
import { StyledButton } from '../GenericStyled/StyledButton'
import { ItemsListBuilder } from './ItemsListBuilder'
import { useShoppingListFormState } from '../../hooks/useShoppingListFormState'

const RecipeFormStyled = styled.div`
    margin: ${s.pageMargin};
    background-color: ${colorPalette.bamboo};
    max-height: 50vh;
    max-width: 60%;
    border: ridge 4px ${colorPalette.mahogany};
    border-radius: 10px;
    padding: 22px 20px;
    display: flex;
    flex-direction: column;
`

const FormBodyStyled = styled.div`
    flex-grow: 1;
`

const FormFooterStyled = styled.div`
    align-self: flex-end;
`

export const ShoppingListForm = ({closeForm}) => {
    const {user, shoppingLists, shoppingListHandlers} = useContext(PaperPlateContext);
    const formState = useShoppingListFormState();

    const {addShoppingList} = shoppingListHandlers
    
    const saveShoppingList = async () => {
        try {
       const response = await axios
            .post('/shoppingLists', {
                data: {
                    shoppingLists: [...shoppingLists, formState.formData],
                    userBlob: user.userBlob
                }
            })
            
        if(response.status !== 200) {
            throw new Error(response.message);
        }

        } catch (err) {
            console.log(err.message)
        } finally {
                addShoppingList(formState.formData);
                formState.clearForm();
                closeForm();
            }
    }

    return (
        <RecipeFormStyled>
            <FormBodyStyled>
                <FormInputControlled
                    nameKey="shoppingListName"
                    displayName="Shopping List Name"
                    type="text"
                    value={formState.shoppingListName}
                    setValue={formState.setShoppingListName}
                />
                <ItemsListBuilder items={formState.items} setItems={formState.setItems}/>
            </FormBodyStyled>

            <FormFooterStyled>
                <StyledButton type="button" onClick={saveShoppingList} mode='primary'>Save Shopping List</StyledButton>
                <StyledButton type="button" onClick={closeForm}>X</StyledButton>
            </FormFooterStyled>
        </RecipeFormStyled>
    )
}
