import {
  useEffect,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useRecipeFormState = () => {
  const [recipeName, setRecipeName] = useState("");
  const [rating, setRating] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [instructions, setInstructions] = useState([]);
  const [formData, setFormData] = useState({});

  const recipeId = uuidv4();

  useEffect(() => {
    const updatedData = {
      recipeId,
      recipeName,
      rating,
      ingredients,
      instructions,
    };

    setFormData(updatedData);
  }, [recipeName, rating, ingredients, instructions]);

  const clearForm = () => {
    setRecipeName("");
    setRating("");
    setIngredients([]);
    setInstructions([]);
    setFormData({});
  };

  return {
    recipeName,
    setRecipeName,
    rating,
    setRating,
    ingredients,
    setIngredients,
    instructions,
    setInstructions,
    formData,
    clearForm,
  };
};
