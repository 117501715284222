import axios from "axios";

export const getRecipes = async (userBlob, replaceRecipes) => {
let response;
        const getApiResponse = async () => {
            try {
                response = await axios.get(`/recipes?userFileName=${userBlob}`);

                if (response.status !== 200) {
                    throw new Error(`Error! status: ${response.status}`);
                  }
                } catch (error) {
                    throw new Error(error.message);
                } finally {
                    replaceRecipes(response.data.recipes ?? []);
                }
        }

        getApiResponse();
    };