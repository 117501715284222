import React, { useState } from 'react';

import styled, { css } from 'styled-components';

const InstructionStyled = styled.p`
    margin: 2px 0;
    
    &:hover {
        cursor: pointer;
    }
    
    ${props => props.strikethrough && css`
         text-decoration: line-through
    `}
`

export const Instruction = (p) => {
    const [shouldRemove, setShouldRemove] = useState('');

    const onClick = () => {
        setShouldRemove(!shouldRemove);
        p.trackRemoveInstruction(p.value);
    };

    return (
        <InstructionStyled onClick={onClick} strikethrough={shouldRemove}>{p.value}</InstructionStyled>
    );
};