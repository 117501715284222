import React, { useContext } from 'react';
import { dashboardModes } from '../../constants/dashboardModes';
import { PaperPlateContext } from '../../store/PaperPlateProvider';
import { SidebarContent } from '../Generic/SidebarContent';
import { StyledButton } from '../GenericStyled/StyledButton';

export const ShoppingLists = ({dashboardMode, addNewShoppingList, combineShoppingLists}) => {
    const {shoppingLists, shoppingListHandlers } = useContext(PaperPlateContext);

    const onShoppingListClick = (shoppingList) => {
        if(dashboardMode === dashboardModes.COMBINE_SHOPPING_LISTS) {
            shoppingListHandlers.addShoppingListToCombine(shoppingList);
        }
    };

    const footerButtons = [
            <StyledButton type='button' onClick={addNewShoppingList}>Add New ShoppingList</StyledButton>,
            <StyledButton type='button' onClick={combineShoppingLists}>Combine Shopping Lists</StyledButton>
    ];

    return (
        <SidebarContent title='Shopping Lists' buttonList={footerButtons}>
            {shoppingLists.map((list) => 
                 <StyledButton key={`${list.shoppingListName}`} onClick={() => onShoppingListClick(list)}>{list.shoppingListName}</StyledButton>
            )}
        </SidebarContent>
    )
};