import { useRef } from "react";

export const useDraggable = () => {

    const dragRef = useRef();

    const dragHandlers = {
        onDragStart: (data) => {
            dragRef.current.data = data
        },

        onDrop: (e, array, setArray) => {
            if(dragRef.current.position !== undefined) {
                e.preventDefault();
                const mutable = [...array];
                mutable[dragRef.current.position] = dragRef.current.data;
                setArray(mutable);
                // dragRef.current = {};
            };
        },

        onDragEnter: (position) => {
            dragRef.current.position = position;
        },

        onDragLeave: () => {
            // dragRef.current.position = undefined;
        }
    }; 

    return {
        dragRef,
        dragHandlers,
    }
}