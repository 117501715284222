import { mealPlanActions} from "./store";

export const mealPlanReducer = (state, action) => {
    switch (action.type) {
      case mealPlanActions.UPDATE_MEAL_PLAN: {
        return {
            ...state,
            mealPlan: action.mealPlan,
        };
        }
      default:
        return state;
    }
  }