import React, { useContext } from 'react';
import { saveData } from '../../helpers/api/saveData';
import { PaperPlateContext } from '../../store/PaperPlateProvider';
import { IconButton } from '../GenericStyled/IconButton';

export const RecipeDeleteButton = ({recipeId}) => {
    const {recipes, user} = useContext(PaperPlateContext);
    const {recipeHandlers} = useContext(PaperPlateContext);

    const handleDelete = async () => {
        const updated = recipes.filter(r => r.recipeId !== recipeId);

        saveData(
            '/recipes',
            { recipes: updated, userBlob: user.userBlob },
            () => recipeHandlers.deleteRecipe(recipeId),
            );
    //     try {
    //         const response = await axios
    //              .post('/userData', {
    //                  data: {
    //                      recipes: updated
    //                  }
    //              })
                 
    //          if(response.status !== 200) {
    //              throw new Error(response.message);
    //          }
     
    //          } catch (err) {
    //              console.log(err.message)
    //          } finally {
    //                  // recipeHandlers.deleteRecipe();
    //              }
    };

    return (
    <IconButton iconName='fa-solid fa-trash-can' onClick={handleDelete}/>
)};