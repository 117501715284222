import React, { useState, useContext } from 'react';

import styled, { css } from 'styled-components';
import { colorPalette } from '../../assets/colorPalette';
import { PaperPlateContext } from '../../store/PaperPlateProvider';

const MealTileStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 0 30%;
    border: ridge 3px ${colorPalette.mahogany};
    border-radius: 10px;
    background-color: ${colorPalette.cardstock};
    padding: 2px;

    &:hover {
        background-color: ${colorPalette.cucumber};
    }

    ${props => props.selected && css`
        background-color: ${colorPalette.cucumber};
    `}
`;

export const MealTile = ({dayMealPlan, position, canSelectForShopping, selectedForShopping, setSelectedForShopping}) => {
    const [hover, setHover] = useState(false);
    const {dragHandlers} = useContext(PaperPlateContext);
    const {onDragEnter: onDragEnterForPlanning, onDragLeave} = dragHandlers;

    const onAddForShopping = () => {
        if(!selectedForShopping.includes(position)) {
            setSelectedForShopping([...selectedForShopping, position]);
        };
    }

    const handleDragEnter = () => {
        if(!canSelectForShopping) {
            onDragEnterForPlanning(position);
        } else {
            onAddForShopping();
        }
    }

    const handleDragLeave = () => {
        if(!canSelectForShopping) {
            onDragLeave();
        }
    }

    return (
        <MealTileStyled 
            hover={hover} 
            draggable={canSelectForShopping} 
            selected={selectedForShopping.includes(position)}
            onClick={onAddForShopping}
            onDragEnter={handleDragEnter} 
            onDragLeave={handleDragLeave} 
            onMouseEnter={() => setHover(true)} 
            onMouseLeave={() => setHover(false)}
        >
            {dayMealPlan.recipeName || 'Empty'}
        </MealTileStyled>
    )
}