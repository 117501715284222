import React from 'react';

import styled from 'styled-components';

import {
  StyledInput,
} from '../components/GenericStyled/StyledInput';
import { StyledLabel } from '../components/GenericStyled/StyledLabel';
import { onPressEnter } from '../helpers/onPressEnter';

const FormInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const FormInputControlled = ({onEnter, value, setValue, type, nameKey, displayName}) => {
    const handleChange = (e) => setValue(e.target.value);

    const handleEnter = () => {
        onEnter(value);
        setValue('');
    }

    return (
        <FormInputWrapper>
            <StyledLabel for={nameKey}>{displayName}</StyledLabel>
            <StyledInput
                type={type}
                name={nameKey}
                value={value}
                onChange={handleChange}
                onKeyPress={(e) => onPressEnter(e, handleEnter)}
            />
        </FormInputWrapper>
    )
}