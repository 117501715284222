import { recipeActions } from "./store";

export const recipeReducer = (state, action) => {
    switch (action.type) {
      case recipeActions.ADD_RECIPE:
        return {
            ...state,
            recipes: [
                ...state.recipes,
                action.recipe
            ]
        }

      case recipeActions.DELETE_RECIPE:
        {
          const updated = state.recipes.filter(x => x.recipeId !== action.recipeId);
        
            return {
            ...state,
            recipes: updated
        };
    }

    case recipeActions.REPLACE_RECIPES:
        return {
            ...state,
            recipes: action.recipes
        }

      default:
        return state;
    }
  }