import React, { useContext, useRef, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import {useReactToPrint} from 'react-to-print';
import { PaperPlateContext } from '../../store/PaperPlateProvider';
import { colorPalette } from '../../assets/colorPalette';
import { StyledCard } from '../GenericStyled/StyledCard';
import { StyledButton } from '../GenericStyled/StyledButton';
import { generateShoppingListFromRecipes } from '../../helpers/generateShoppingListFromRecipes';

const ShoppingListStyled = styled.div`
        color: ${colorPalette.charcoal};
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        gap: 10px;
    `

const ShoppingListFooterStyled = styled.div`
    display: flex;
    align-self: flex-end;
`

const SelectedRecipesContainerStyled = styled.div`
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    background-color: ${colorPalette.charcoal};
    border: outset 2px ${colorPalette.charcoal};
    padding: 15px;
    border-radius: 5px;
`

const SelectedRecipeStyled = styled.div`
    padding: 1.3em 0.8em;
    border: solid 2px ${colorPalette.charcoal};
    border-radius: 5px;
    background-color: ${colorPalette.bamboo};
`

const GeneratedListContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    border: solid 2px ${colorPalette.charcoal};
    padding: 10px;
    border-radius: 5px;
`

const ListItemStyled = styled.span`
    padding: 2px 3px;
`

export const ShoppingListGenerator = ({closeView}) => {
    const {user, shoppingLists, shoppingListRecipes, shoppingListHandlers} = useContext(PaperPlateContext);

    const [list, setList] = useState([]);

    const onGenerateList = () => {
        const generated = generateShoppingListFromRecipes(list);
        setList(generated);
    };

    const handleClose = () => {
        shoppingListHandlers.clearRecipesForList();
        closeView();
    }

    const saveShoppingList = async () => {
        const generatedShoppingList = {shoppingListName: 'Generated', items: list}; 
        try {
       const response = await axios
            .post('/shoppingLists', {
                data: {
                    shoppingLists: [...shoppingLists, generatedShoppingList],
                    userBlob: user.userBlob
                }
            })
            
        if(response.status !== 200) {
            throw new Error(response.message);
        }

        } catch (err) {
            console.log(err.message)
        } finally {
                shoppingListHandlers.addShoppingList(generatedShoppingList);
                handleClose();
            }
    };

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
            border: none;

            @page {
                margin: 0 50mm;
            }
        `
    })

    return (
        <StyledCard>
            <ShoppingListStyled>
                <SelectedRecipesContainerStyled>
                {shoppingListRecipes.map(x => <SelectedRecipeStyled>{x.recipeName}</SelectedRecipeStyled>)}
                </SelectedRecipesContainerStyled>
                {list.length > 0 && 
                    <GeneratedListContainerStyled ref={printRef}>
                        <h1>Shopping List</h1>
                        {list.map(x => <ListItemStyled>{x}</ListItemStyled>)}
                    </GeneratedListContainerStyled>
                    }
            </ShoppingListStyled>
                <ShoppingListFooterStyled>
                    <StyledButton type='button' onClick={onGenerateList} mode='primary'>Generate Shopping List</StyledButton>
                    <StyledButton type='button' onClick={saveShoppingList} mode="primary">Save Shopping List</StyledButton>
                    <StyledButton type='button' onClick={handlePrint} mode='secondary'>Print</StyledButton>
                    <StyledButton type='button' onClick={handleClose}>X</StyledButton>
                </ShoppingListFooterStyled>
        </StyledCard>
    )
}