import React, { useState } from 'react';

import styled from 'styled-components';

import { FormTextArea } from '../../FormComponents/FormTextArea';
import { SecondaryButtonStyled } from '../GenericStyled/StyledSecondaryButton';
import { Instruction } from './Instruction';

// const IngredientsListBuilderStyled = styled.div`
//     margin: ${s.pageMargin}
// `

const InstructionsListStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    border: 2px black solid;
    border-radius: 10px;
    padding: 10px;
    max-height: 400px;
`

export const InstructionsListBuilder = (p) => {
    const [removeOnUpdate, setRemoveOnUpdate] = useState([]);

    const onEnterInstruction = (input) => {
        const updated = [...p.instructions, input];
        p.setInstructions(updated);
    }

    const trackRemoveInstruction = (instruction) => {
        if (removeOnUpdate.includes(instruction)) {
            const updated = removeOnUpdate.filter((x) => x === instruction);
            setRemoveOnUpdate(updated);
        } else {
            setRemoveOnUpdate([...removeOnUpdate, instruction]);
        }
    }

    const updateToRemove = () => {
        let updated = p.instructions;

        removeOnUpdate.forEach((removed) => {
            updated = updated.filter((instruction) => instruction !== removed);
        });

        p.setInstructions(updated);
        setRemoveOnUpdate([]);
    }

    return (
        <div>
            <FormTextArea nameKey="instruction" displayName="Enter Instruction" rows={5} cols={300} onEnter={onEnterInstruction} />
            {p.instructions.length > 0 &&
                <>
                    <InstructionsListStyled>
                        {p.instructions.map(instruction =>
                            <Instruction
                                key={instruction}
                                value={instruction}
                                trackRemoveInstruction={trackRemoveInstruction}
                            />
                        )}
                    </InstructionsListStyled>
                    <SecondaryButtonStyled onClick={updateToRemove}>Update</SecondaryButtonStyled>
                </>
            }
        </div>
    )
}