import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { getInitialData } from '../api/getInitialData';
import { dashboardModes } from '../constants/dashboardModes';
import { mealPlanModes } from '../constants/mealPlanModes';
import { PaperPlateContext } from '../store/PaperPlateProvider';
import { Header } from './Header/Header';
import { LeftBar } from './LeftBar/LeftBar';
import { MealPlan } from './MealPlan/MealPlan';
import { RecipeForm } from './Recipes/RecipeForm';
import { ShoppingListCombiner } from './ShoppingLists/ShoppingListCombiner';
import { ShoppingListForm } from './ShoppingLists/ShoppingListForm';
import { ShoppingListGenerator } from './ShoppingLists/ShoppingListGenerator';

const DashboardStyled = styled.div`
    display: flex;
    flex-direction: row;
    background-image: url('backgroundImage.jpg');
    background-size: cover;
    height: 100vh;
`;

export const Dashboard = () => {
    const [mode, setMode] = useState(dashboardModes.DEFAULT);
    const [mealPlanMode, setMealPlanMode] = useState(mealPlanModes.PLANNING);
    const {user, userHandlers, shoppingListHandlers, recipeHandlers, mealPlanHandlers} = useContext(PaperPlateContext);

    const closeView = () => setMode(dashboardModes.DEFAULT);

    const handlers = {
        shoppingListHandlers,
        recipeHandlers,
        mealPlanHandlers,
    };

    useEffect(() => {
        getInitialData(user.userBlob, handlers);
    }, [user]);


    return ( !!user.userBlob && 
        <>
        <Header logout={() => userHandlers.updateUser({})}/>
        <DashboardStyled>
            <LeftBar dashboardMode={mode} setDashboardMode={setMode} canMealPlan={mealPlanMode === mealPlanModes.PLANNING}/>
            {mode === dashboardModes.RECIPE_FORM && <RecipeForm closeForm={closeView} />}
            {mode === dashboardModes.GENERATE_SHOPPING_LIST && <ShoppingListGenerator closeView={closeView} />}
            {mode === dashboardModes.SHOPPING_LIST_FORM && <ShoppingListForm closeForm={closeView} />}
            {mode === dashboardModes.COMBINE_SHOPPING_LISTS && <ShoppingListCombiner closeView={closeView} />}
            {mode === dashboardModes.DEFAULT && <MealPlan mealPlanMode={mealPlanMode} setMealPlanMode={setMealPlanMode} />}
        </DashboardStyled>
        </>
    )
}