import React from 'react';
import styled from 'styled-components';

import {
    inputMargin,
    inputPadding,
} from './StyledInput';
import { StyledTextArea } from './StyledTexArea';

const TextAreaWithSubmit = styled.div`
    display: flex;
`

const StyledInputSubmit = styled.button`
    padding: ${inputPadding};
    margin: ${inputMargin}
    background-color: white;
`

export const StyledTextAreaWithButton = (p) =>
    <TextAreaWithSubmit>
        <StyledTextArea
            type={p.type}
            name={p.name}
            value={p.value}
            rows={p.rows}
            cols={p.cols}
            onChange={p.onChange}
            onKeyPress={p.onKeyPress}
        />
        <StyledInputSubmit type="submit" onClick={p.handleEnter}>Add</StyledInputSubmit>
    </TextAreaWithSubmit>