import React, { useState } from 'react';

import styled from 'styled-components';

import { StyledLabel } from '../components/GenericStyled/StyledLabel';
import {
    StyledTextAreaWithButton,
} from '../components/GenericStyled/StyledTextAreaWithButton';
import { onPressEnter } from '../helpers/onPressEnter';

const FormTextAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

export const FormTextArea = (p) => {
    const [value, setValue] = useState('');

    const handleChange = (e) => setValue(e.target.value);

    const handleEnter = () => {
        p.onEnter(value);
        setValue('');
    }

    return (
        <FormTextAreaWrapper>
            <StyledLabel for={p.nameKey}>{p.displayName}</StyledLabel>
            <StyledTextAreaWithButton
                type={p.type}
                name={p.nameKey}
                value={value}
                rows={p.rows}
                cols={p.cols}
                onChange={handleChange}
                onKeyPress={(e) => onPressEnter(e, handleEnter)}
            />
        </FormTextAreaWrapper>
    )
}