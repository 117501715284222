import {
    useEffect,
    useState,
  } from 'react';
  
  export const useShoppingListFormState = () => {
    const [shoppingListName, setShoppingListName] = useState("");
    const [items, setItems] = useState([]);
    const [formData, setFormData] = useState({});
  
    useEffect(() => {
      const updatedData = {
        shoppingListName,
        items,
      };
  
      setFormData(updatedData);
    }, [shoppingListName, items]);
  
    const clearForm = () => {
      setShoppingListName("");
      setItems([]);
      setFormData({});
    };
  
    return {
      shoppingListName,
      setShoppingListName,
      items,
      setItems,
      formData,
      clearForm,
    };
  };
  