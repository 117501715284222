import axios from "axios";
import { getInitialMealPlan } from "../store/store";

export const getMealPlans = (userBlob, updateMealPlan) => {
    let response;
    const getApiResponse = async () => {
        try {
            response = await axios.get(`/mealPlans?userFileName=${userBlob}`);

            if (response.status !== 200) {
                throw new Error(`Error! status: ${response.status}`);
              }
            } catch (error) {
                throw new Error(error.message);
            } finally {
                updateMealPlan(response.data.mealPlan ?? getInitialMealPlan());
            }
    }

    getApiResponse();
};