export const generateShoppingListFromRecipes = (recipeList) => {
        const allIngredients = recipeList.reduce((acc, cur) => {
                if(!cur.ingredients) {
                    return acc;
                };
                
                cur.ingredients.forEach(item => {
                    acc.push(item)
                });
                return acc;
            }, []);

        const uniqueIngredients = new Set(allIngredients);
        const finalList = Array.from(uniqueIngredients);
        
        return finalList;
};