import React from 'react';
import styled from 'styled-components';
import { Dashboard } from './components/Dashboard';
import { Login } from './components/Login/Login';
import { PaperPlateProvider } from './store/PaperPlateProvider';

const AppStyled = styled.div`
    display: flex;
    flex-direction: column;
    background-image: url('backgroundImage.jpg');
    background-size: cover;
    height: 100vh;
`;

const App = () => (
    <PaperPlateProvider>
      <AppStyled className="App">
        <Login />
        <Dashboard />
      </AppStyled>
    </PaperPlateProvider>
  );

export default App;
