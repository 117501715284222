export const colorPalette = {
  subtle: "#525252",
  bamboo: '#ebcea2',
  bamboo_dd: '#ebcea2dd',
  mahogany: '#8f552f',
  mahogany_dd: '#8f552fdd',
  cardstock: '#a37658',
  ash: '#faf0e8',
  milk_chocolate: '#c99f83',
  charcoal: '#1e292f',
  cucumber: '#a6cc90',
  rainforest: '##132e19',
  rainforest_cc: '#132e19cc',
};
