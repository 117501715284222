import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';
import {useReactToPrint} from 'react-to-print';
import { PaperPlateContext } from '../../store/PaperPlateProvider';
import { colorPalette } from '../../assets/colorPalette';
import { StyledCard } from '../GenericStyled/StyledCard';
import { StyledButton } from '../GenericStyled/StyledButton';

const ShoppingListStyled = styled.div`
        color: ${colorPalette.charcoal};
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        gap: 10px;
    `

const ShoppingListFooterStyled = styled.div`
    display: flex;
    align-self: flex-end;
`

const SelectedRecipesContainerStyled = styled.div`
    display: flex;
    flex-flow: wrap;
    gap: 20px;
    background-color: ${colorPalette.charcoal};
    border: outset 2px ${colorPalette.charcoal};
    padding: 15px;
    border-radius: 5px;
`

const SelectedRecipeStyled = styled.div`
    padding: 1.3em 0.8em;
    border: solid 2px ${colorPalette.charcoal};
    border-radius: 5px;
    background-color: ${colorPalette.bamboo};
`

const GeneratedListContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 75vh;
    border: solid 2px ${colorPalette.charcoal};
    padding: 10px;
    border-radius: 5px;
`

const ListItemStyled = styled.span`
    padding: 2px 3px;
`

export const ShoppingListCombiner = ({closeView}) => {
    const {shoppingListsToCombine, shoppingListHandlers} = useContext(PaperPlateContext);

    const [list, setList] = useState([]);
    const onCombineList = () => {
        const allItems = shoppingListsToCombine.reduce((acc, cur) => {
                cur.items.forEach(item => {
                    acc.push(item)
                });
                return acc;
            }, []);
        const allItemsLowercase = allItems.map(item => item.toLowerCase());
        allItemsLowercase.sort();

        const uniqueItems = new Set(allItemsLowercase);
        const finalList = Array.from(uniqueItems);
        
        setList(finalList);
    };

    const handleClose = () => {
        shoppingListHandlers.clearListsToCombine();
        setList([]);
        closeView();
    }

    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        pageStyle: `
            border: none;

            @page {
                margin: 0 50mm;
            }
        `
    })

    return (
        <StyledCard>
            <ShoppingListStyled>
                <SelectedRecipesContainerStyled>
                {shoppingListsToCombine.map(x => <SelectedRecipeStyled>{x.shoppingListName}</SelectedRecipeStyled>)}
                </SelectedRecipesContainerStyled>
                {list.length > 0 && 
                    <GeneratedListContainerStyled ref={printRef}>
                        <h1>Shopping List</h1>
                        {list.map(x => <ListItemStyled>{x}</ListItemStyled>)}
                    </GeneratedListContainerStyled>
                    }
            </ShoppingListStyled>
                <ShoppingListFooterStyled>
                    <StyledButton type='button' onClick={onCombineList} mode='primary'>Combine Shopping List</StyledButton>
                    <StyledButton type='button' onClick={handlePrint} mode='secondary'>Print</StyledButton>
                    <StyledButton type='button' onClick={handleClose}>X</StyledButton>
                </ShoppingListFooterStyled>
        </StyledCard>
    )
}