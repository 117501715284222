import styled from 'styled-components';
import { colorPalette } from '../../assets/colorPalette';

export const StyledCard = styled.div`
    background-color: ${colorPalette.bamboo};
    border: ridge 4px ${colorPalette.mahogany};
    border-radius: 10px;
    padding: 22px 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 2;
`