import React, { useContext } from 'react';
import { PaperPlateContext } from '../../store/PaperPlateProvider';
import { SidebarContent } from '../Generic/SidebarContent';
import { StyledButton } from '../GenericStyled/StyledButton';
import { RecipeButton } from './RecipeButton';

export const Recipes = ({addNewRecipe, isCreatingShoppingList, showShoppingList, canMealPlan}) => {
    const {recipes, shoppingListHandlers } = useContext(PaperPlateContext);

    const onRecipeClick = (recipe) => {
        if(isCreatingShoppingList) {
            shoppingListHandlers.addRecipeToShoppingList(recipe);
        }
    };

    const footerButtons = [
            <StyledButton type='button' onClick={addNewRecipe}>Add New Recipe</StyledButton>,
            <StyledButton type='button' onClick={showShoppingList}>Create Shopping List</StyledButton>
    ];

    return (
        <SidebarContent title='Menu' buttonList={footerButtons}>
            {recipes.map((recipe) => 
                    <RecipeButton key={`${recipe.recipeName}`} onClick={onRecipeClick} recipe={recipe} unselectAll={!isCreatingShoppingList} canMealPlan={canMealPlan} withDelete />
                )}
        </SidebarContent>
    )
};