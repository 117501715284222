import axios from "axios";

export const saveData = async (endpoint, data, onSuccess) => {
    try {
   const response = await axios
        .post(endpoint, {
            data
        })
        
    if(response.status !== 200) {
        throw new Error(response.message);
    }

    onSuccess(response.data);
    } catch (err) {
        console.error(err.message)
    }
}