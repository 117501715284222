import { shoppingListActions } from "./store";

export const shoppingListReducer = (state, action) => {
    switch (action.type) {
      case shoppingListActions.ADD_SHOPPING_LIST:
        return {
          ...state,
          shoppingLists: [
            ...state.shoppingLists,
            action.shoppingList
          ]
        };

      case shoppingListActions.REPLACE_SHOPPING_LISTS:
        return {
          ...state,
          shoppingLists: action.shoppingLists
        }
        
      case shoppingListActions.ADD_RECIPE_TO_SHOPPING_LIST:
        return {
            ...state,
            shoppingListRecipes: [
                ...state.shoppingListRecipes,
                action.recipe
            ]
        };

        case shoppingListActions.ADD_SHOPPING_LIST_TO_COMBINE:
          return {
            ...state,
            shoppingListsToCombine: [
              ...state.shoppingListsToCombine,
              action.shoppingList
            ]
          };
      
        case shoppingListActions.CLEAR_RECIPES_FOR_LIST:
          return {
            ...state,
            shoppingListRecipes: [],
          }

        case shoppingListActions.CLEAR_LISTS_TO_COMBINE:
          return {
            ...state,
            shoppingListsToCombine: [],
          }
          
      default:
        return state;
    }
  }