export const getInitialMealPlan = () => {
    const initialMealPlan = new Array(28);
    return initialMealPlan.fill({});
}

export const initialState = {
    user: {},
    recipes: [],
    mealPlan: getInitialMealPlan(),
    shoppingLists: [],
    shoppingListRecipes: [],
    shoppingListsToCombine: [],
};

export const userActions = {
    UPDATE_USER: 'UPDATE_USER',
}

export const recipeActions = {
    ADD_RECIPE: 'ADD_RECIPE',
    DELETE_RECIPE: 'DELETE_RECIPE',
    REPLACE_RECIPES: 'REPLACE_RECIPES',
    UPDATE_RECIPE: 'UPDATE_RECIPE'
};

export const shoppingListActions = {
    ADD_SHOPPING_LIST: 'ADD_SHOPPING_LIST',
    REPLACE_SHOPPING_LISTS: 'REPLACE_SHOPPING_LISTS',
    ADD_RECIPE_TO_SHOPPING_LIST: 'ADD_RECIPE_TO_SHOPPING_LIST',
    ADD_SHOPPING_LIST_TO_COMBINE: 'ADD_SHOPPING_LIST_TO_COMBINE',
    CLEAR_RECIPES_FOR_LIST: 'CLEAR_RECIPES_FOR_LIST',
    CLEAR_LISTS_TO_COMBINE: 'CLEAR_LISTS_TO_COMBINE',
};

export const mealPlanActions = {
    UPDATE_MEAL_PLAN: 'ADD_RECIPE_TO_MEAL_PLAN',
}