import React from 'react';
import styled from "styled-components";
import { colorPalette } from "../../assets/colorPalette";

const IconButtonStyled = styled.button`
    background-color: inherit;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;

    &:hover {
        opacity: 80%;
        color: ${colorPalette.milk_chocolate}
    }
`;

export const IconButton = ({iconName, onClick}) => 
<IconButtonStyled  onClick={onClick}>
    <i icon={iconName} />
        D
</IconButtonStyled>