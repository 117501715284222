import axios from "axios";

export const getShoppingLists = (userBlob, replaceShoppingLists) => {
    let response;
    const getApiResponse = async () => {
        try {
            response = await axios.get(`/shoppingLists?userFileName=${userBlob}`);

            if (response.status !== 200) {
                throw new Error(`Error! status: ${response.status}`);
              }
            } catch (error) {
                throw new Error(error.message);
            } finally {
                replaceShoppingLists(response.data.shoppingLists ?? []);
            }
    }

    getApiResponse();
}