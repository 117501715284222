import React, { useState } from 'react';

import styled, { css } from 'styled-components';

const ItemStyled = styled.span`
    &:hover {
        cursor: pointer;
    }
    
    ${props => props.strikethrough && css`
         text-decoration: line-through
    `}
`

export const Item = ({value, trackRemoveItem}) => {
    const [shouldRemove, setShouldRemove] = useState('');

    const onClick = () => {
        setShouldRemove(!shouldRemove);
        trackRemoveItem(value);
    };

    return (
        <ItemStyled onClick={onClick} strikethrough={shouldRemove}>{value}</ItemStyled>
    );
};